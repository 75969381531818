import $ from 'jquery'

const EVENTS_TYPE_SELECTOR = '.js-ar_object_events_type'
const GROUP_SELECTOR = '.js-event_group'
const hideSelector = '.event_values'
const changeConditions = {
  '5': "#ar_object_events_activate_distance",
  '6': "#ar_object_events_look_at_time",
  '8': "#ar_object_events_moments"
}

$(function () {
  $(document).on('change', EVENTS_TYPE_SELECTOR, function (e) {
    const $eventsType = $(e.currentTarget)
    const eventType = $eventsType.val()
    const parentNode = $eventsType.parents(GROUP_SELECTOR + ':eq(0)').get(0)

    const search = function (selector) {
      return $(selector, parentNode)
    }

    const targetChangeType = changeConditions[eventType]
    if (!targetChangeType) {
      search(hideSelector).css('display', 'none')
    }
    search(hideSelector).css('display', 'none')
    search(targetChangeType).css('display', 'block')
  })
  $(GROUP_SELECTOR).each(function () {
    const $group = $(this)
    const $eventsType = $group.find(EVENTS_TYPE_SELECTOR + ':eq(0)')
    const eventsType = $eventsType.val()

    const search = function (selector) {
      return $(selector, $group.get(0))
    }

    const targetChangeType = changeConditions[eventsType]
    if (targetChangeType) {
      search(hideSelector).css('display', 'none')
      search(targetChangeType).css('display', 'block')
    }
  })
})
